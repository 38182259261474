import React from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { laptopSize, mobileSize } from "../../../util/variables";
import { AppButton, AppText } from "../../ui";
import { graphql, navigate, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

interface ClaimOfferProps {
  cid: string;
  data: {
    id: string;
    discount: number; // fixed $ amount
    duration: string;
  };
}

export const ClaimOffer: React.FC<ClaimOfferProps> = React.memo(({ cid, data }) => {
  const imageData = useStaticQuery(graphql`
    query ClaimOfferImageQuery {
      desktop: file(relativePath: { eq: "homepage/deep-understanding-desktop.webp" }) {
        childImageSharp {
          fixed(quality: 100, height: 610, width: 838) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      mobile: file(relativePath: { eq: "homepage/deep-understanding-desktop.webp" }) {
        childImageSharp {
          fixed(quality: 100, height: 184, width: 307) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const handleClick = () => {
    navigate(`/subscription/register?cid=${cid}`);
  };

  const getDiscountPercentage = () => Math.floor(100 - (99.99 - data.discount));

  return (
    <div>
      <Title>Save {getDiscountPercentage()}% on Imprint Premium</Title>
      <FormContainer>
        <MobileImgWrapper>
          <Img
            fixed={imageData.mobile.childImageSharp.fixed}
            alt="magnifying glass focused on a butterfly on a sunflower."
          />
        </MobileImgWrapper>
        <ImgWrapper>
          <Img
            fixed={imageData.desktop.childImageSharp.fixed}
            alt="magnifying glass focused on a butterfly on a sunflower."
          />
        </ImgWrapper>
        <Title2>Claim your offer and start learning visually</Title2>
        <AppText>
          <b>Annual Subscription</b> ({getDiscountPercentage()}% Discount)
        </AppText>
        <Prices>
          <AppText
            noMargin
            color={theme.SLATE_COLOR_3}
            fontWeight={300}
            fontSize={52}
            mobileFontSize={24}
            style={{ textDecoration: "line-through 2px" }}
          >
            $99.99
          </AppText>
          <AppText noMargin color={theme.PRIMARY_COLOR} fontSize={52} mobileFontSize={40}>
            <b>${99.99 - data.discount}</b>
          </AppText>
        </Prices>
        <AppText color={theme.SLATE_COLOR_2}>
          ${99.99 - data.discount} for the first year. Then, $99.99 after.
        </AppText>
        <HideDiv showDesktop>
          <AppButton onClick={handleClick}>Claim Offer</AppButton>
        </HideDiv>
      </FormContainer>
      <HideDiv showMobile>
        <AppButton style={{ border: `2px solid ${theme.WHITE_COLOR}` }} onClick={handleClick}>
          Claim Offer
        </AppButton>
      </HideDiv>
    </div>
  );
});

const Title = styled.h1`
  font-size: 40px;
  font-weight: 600;
  color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    font-size: 24px;
    font-weight: 700;
  }
`;

const Title2 = styled.h2`
  font-size: 32px;
  font-weight: 500;
  max-width: 415px;
  @media ${mobileSize} {
    max-width: 190px;
    font-size: 24px;
  }
`;

const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1000px;
  width: 90vw;
  padding: 80px 48px;
  margin: auto;
  margin-top: 31px;
  border-radius: 16px;
  background-color: #fafafa;
  overflow: hidden;

  @media ${mobileSize} {
    padding: 40px 20px;
    min-height: 400px;
    margin-bottom: 24px;
  }
`;

const ImgWrapper = styled.div`
  position: absolute;
  right: -136px;
  top: -32px;
  height: 100%;
  @media screen and (max-width: 850px) {
    /* right: -200px; */
    display: none;
  }
`;
const MobileImgWrapper = styled.div`
  display: none;
  position: absolute;
  right: -56px;
  top: 0;
  height: 100%;
  @media screen and (max-width: 850px) {
    display: block;
  }
`;

const HideDiv = styled.div<{ showDesktop?: boolean; showMobile?: boolean }>`
  display: ${props => (props.showDesktop ? "block" : "none")};
  @media ${mobileSize} {
    display: ${props => (props.showDesktop ? "none" : "block")};
  }
`;

const Prices = styled.div`
  display: flex;
  gap: 22px;
  margin-bottom: 44px;
  @media ${laptopSize} {
    flex-direction: column;
    gap: 0;
  }
`;
