import { navigate } from "gatsby";
import React, { useEffect } from "react";
import styled from "styled-components";
import { ClaimOffer } from "../../components/PageSegments/subscription/ClaimOffer";
import { AppLogo } from "../../components/ui";
import { BasicLayout } from "../../layouts/BasicLayout";
import theme from "../../util/theme";
import { COUPON_DISCOUNT_DATA, mobileSize } from "../../util/variables";

export default (data: any) => {
  const { search } = data.location;
  const urlParams = new URLSearchParams(search);
  const couponParam = urlParams.get("cid");
  const coupon = COUPON_DISCOUNT_DATA.find(item => item.id === couponParam);
  useEffect(() => {
    console.log(couponParam);
    if (!couponParam || !coupon) {
      navigate("/");
    }
  }, [couponParam, coupon]);

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <LogoContainer>
          <AppLogo white size="medium" />
        </LogoContainer>
        {couponParam && coupon && <ClaimOffer cid={couponParam} data={coupon} />}
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: ${theme.PRIMARY_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
    padding-bottom: 24px;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 100px;
  top: 80px;
  display: block;
  @media screen and (max-height: 900px) {
    position: static;
  }
  @media screen and (max-width: 1204px) {
    position: static;
    margin-left: 48px;
    margin-right: auto;
    margin-bottom: 16px;
  }
  
  @media ${mobileSize} {
    position: static;
    margin: 64px 0;
  }
`;
